import React from 'react'
import { useMemo, useEffect, useState} from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { subMonths, addMonths, parseISO, format } from 'date-fns'

import Container from '@mui/material/Container'
import CssBaseline from '@mui/material/CssBaseline'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

// @ts-ignore
// import { MetricsBar } from 'embed-app'
// import { withData } from 'embed-app'

// import { Test, Test2 } from 'embed-app'
import { DataProvider, Head, MetricsBar, CompositionBar, LineChart, TextBlock } from 'embed-app'

// import { Typography } from 'embed-app'
// console.log('Typography', Typography)
import './App.css'
import { Typography } from '@mui/material'

// import * as test from 'embed-app'
// console.log(Object.keys(test))

// console.log('withData', withData)
// console.log('MetricsBar', MetricsBar)

const getPeriod = ({ year, month, direction = 'next' } : { year: string, month: string, direction: string }) => {
  const change = direction === 'next' ? addMonths : subMonths
  const dd = change(parseISO(`${year}-${month}-01`), 1)
  return dd.toLocaleDateString('default', { year: 'numeric', month: '2-digit' }).split('/')
}

interface PeriodProps {
  exists: boolean,
  year?: string,
  month?: string
}

function App () {
  //  : { exists: Boolean, year: string, month: string }
  const [next, setNext] = useState<PeriodProps>({ exists: false })
  const [prev, setPrev] = useState<PeriodProps>({ exists: false })

  const navigate = useNavigate()
  const {
    year = String(new Date().getFullYear()),
    month = new Date().toLocaleDateString('default', { month: '2-digit' })
  } = useParams()

  useEffect(() => {
    const effect = async () => {
      let tryYear = year
      let tryMonth = month
      let i = 0
      while (true) {
        try {
          if (i > 12) {
            // TODO need to make error page?
            console.warn('Breakout loop condition reached')
            break
          }

          const url = `${process.env.REACT_APP_DATA_HOST}/givingpulse/${tryYear}/${tryMonth}/generosity-action-index.json`
          await Head(url)
          navigate(`/${tryYear}/${tryMonth}`, { replace: true })
          break
        } catch (err) {
          const [m, y] = getPeriod({
            year: tryYear,
            month: tryMonth,
            direction: 'prev'
          })

          tryYear = y
          tryMonth = m
          i++
        }
      }
    }

    effect()
  }, [year, month, navigate])

  useEffect(() => {
    // Check if we have files for the previous month and the next month
    // TODO this will cause a double tap with the above effect
    const effect = async () => {
      const [nextMonth, nextYear] = getPeriod({
        year,
        month,
        direction: 'next'
      })

      try {
        await Head(`${process.env.REACT_APP_DATA_HOST}/givingpulse/${nextYear}/${nextMonth}/generosity-action-index.json`)
        setNext({
          exists: true,
          month: nextMonth,
          year: nextYear
        })
      } catch (err) {
        setNext({
          exists: false,
          month: nextMonth,
          year: nextYear
        })
      }

      const [prevMonth, prevYear] = getPeriod({
        year,
        month,
        direction: 'prev'
      })

      try {
        await Head(`${process.env.REACT_APP_DATA_HOST}/givingpulse/${prevYear}/${prevMonth}/generosity-action-index.json`)
        setPrev({
          exists: true,
          month: prevMonth,
          year: prevYear
        })
      } catch (err) {
        setPrev({
          exists: false,
          month: prevMonth,
          year: prevYear
        })
      }
    }

    effect()
  }, [year, month])

  const urls = useMemo(() => ({
    generosityActionIndex: `${process.env.REACT_APP_DATA_HOST}/givingpulse/${year}/${month}/generosity-action-index.json`,
    generositySentimentIndex: `${process.env.REACT_APP_DATA_HOST}/givingpulse/${year}/${month}/generosity-sentiment-index.json`,
    generousBehaviors: `${process.env.REACT_APP_DATA_HOST}/givingpulse/${year}/${month}/generous-behaviors.json`,
    generousBehaviorsWeekly: `${process.env.REACT_APP_DATA_HOST}/givingpulse/${year}/${month}/generous-behaviors-weekly.json`,
    givingProfiles: `${process.env.REACT_APP_DATA_HOST}/givingpulse/${year}/${month}/giving-profiles.json`,
    textBlockPage1: `${process.env.REACT_APP_DATA_HOST}/givingpulse/${year}/${month}/text-block-page-1.json`,
    textBlockPage2: `${process.env.REACT_APP_DATA_HOST}/givingpulse/${year}/${month}/text-block-page-2.json`,
    textBlockPage3: `${process.env.REACT_APP_DATA_HOST}/givingpulse/${year}/${month}/text-block-page-3.json`
  }), [year, month])

  return (
    <React.Fragment>
      <CssBaseline />

      {prev.year && prev.year &&
        <Button
          variant='outlined'
          size='large'
          startIcon={<ArrowBackIosIcon />}
          style={{ position: 'fixed', left: -5 }}
          disabled={!prev.exists}
          href={`/${prev.year}/${prev.month}`}
        >
          {format(new Date(parseISO(`${prev.year}-${prev.month}-01`)), 'MMM y')}
        </Button>}

      {next.year && next.year &&
        <Button
          variant='outlined'
          size='large'
          endIcon={<ArrowForwardIosIcon />}
          style={{ position: 'fixed', right: -5 }}
          disabled={!next.exists}
          href={`/${next.year}/${next.month}`}
        >
          {format(new Date(parseISO(`${next.year}-${next.month}-01`)), 'MMM y')}
        </Button>}

      <Container maxWidth='lg' style={{ paddingTop: '2em' }}>
        <Stack spacing={6}>
          <TextBlock
            header='GivingPulse Monthly Report'
            subHeader={format(new Date(parseISO(`${year}-${month}-01`)), 'MMM y')}
            headerVariant='h4'
            subHeaderVariant='h5'
          />
          <DataProvider src={urls.textBlockPage1}>
            <TextBlock />
          </DataProvider>

          <DataProvider src={urls.generosityActionIndex}>
            <LineChart
              options={{
                scales: {
                  x: {
                    title: {
                      display: true,
                      text: 'Week of collection',
                      font: {
                        size: 16
                      },
                      padding: 10
                    },
                  }
                },
                plugins: {
                  annotation: {
                    annotations: {
                      boxAnnotation: {
                        type: 'box',
                        xMin: 14,
                        xMax: 18,
                        yMin: 1.5,
                        yMax: 1.8,
                        backgroundColor: 'rgba(242, 244, 255, 0.5)',
                        borderWidth: 0,
                        drawTime: 'beforeDatasetsDraw'
                      },
                      median2023: {
                        type: 'label',
                        color: '#166cad',
                        content: (context: any) => {
                          const val = context.chart?.data?.datasets?.[1]?.data?.[0] ?? 0
                          return `2023 median: ${val}`
                        },
                        font: {
                          size: 14,
                          weight: 'bold'
                        },
                        xValue: 0.25,
                        yValue: (context: any) => context.chart?.data?.datasets?.[1]?.data?.[0] ?? 0,
                        yAdjust: -18
                      },
                      median2022: {
                        type: 'label',
                        color: '#1eb19a',
                        content: (context: any) => {
                          const val = context.chart?.data?.datasets?.[2]?.data?.[0] ?? 0
                          return `2022 median: ${val}`
                        },
                        font: {
                          size: 14,
                          weight: 'bold'
                        },
                        xValue: 0.25,
                        yValue: (context: any) => context.chart?.data?.datasets?.[2]?.data?.[0] ?? 0,
                        yAdjust: -18,
                        align: 'start'

                      }
                    }
                  },
                  datalabels: {
                    formatter: (value: string, context: any) => context.datasetIndex === 0 ? value : '',
                    display: function (context: any) {
                      const dataIndex = context.dataIndex ?? []
                      const dataLength = context.dataset.data.length
                      return dataIndex >= dataLength - 4
                    },
                    color: 'black',
                    font: {
                      size: 16
                    },
                    align: -120,
                    clamp: true,
                    offset: 10
                  }
                },
                elements: {
                  line: {
                    borderWidth: (context: any) => context.dataset.label === '' ? 4 : 2.5
                  }
                }
              }}
            />
          </DataProvider>

          <DataProvider src={urls.generositySentimentIndex}>
            <LineChart
              options={{
                scales: {
                  x: {
                    title: {
                      display: true,
                      text: 'Week of collection',
                      font: {
                        size: 16
                      },
                      padding: 10
                    },
                  }
                },
                plugins: {
                  annotation: {
                    annotations: {
                      boxAnnotation: {
                        type: 'box',
                        xMin: 14,
                        xMax: 18,
                        yMin: 0,
                        yMax: 7,
                        backgroundColor: 'rgba(242, 244, 255, 0.5)',
                        borderWidth: 0,
                        drawTime: 'beforeDatasetsDraw'
                      },
                      median2023: {
                        type: 'label',
                        color: '#166cad',
                        content: (context: any) => {
                          const val = context.chart?.data?.datasets?.[1]?.data?.[1] ?? 0
                          return `2023 median: ${val}`
                        },
                        font: {
                          size: 14,
                          weight: 'bold'
                        },
                        xValue: 0.25,
                        yValue: (context: any) => context.chart?.data?.datasets?.[1]?.data?.[0] ?? 0,
                        yAdjust: -18,
                        drawTime: 'afterDatasetsDraw'
                      },
                      median2022: {
                        type: 'label',
                        color: '#1eb19a',
                        content: (context: any) => {
                          const val = context.chart?.data?.datasets?.[2]?.data?.[1] ?? 0
                          return `2022 median: ${val}`
                        },
                        font: {
                          size: 14,
                          weight: 'bold'
                        },
                        xValue: 0.25,
                        yValue: (context: any) => context.chart?.data?.datasets?.[2]?.data?.[0] ?? 0,
                        yAdjust: -36,
                        drawTime: 'afterDatasetsDraw'
                      }
                    }
                  },
                  datalabels: {
                    formatter: (value: string, context: any) => context.datasetIndex === 0 ? value : '',
                    display: function (context: any) {
                      const dataIndex = context.dataIndex
                      const dataLength = context.dataset.data.length
                      return dataIndex >= dataLength - 4
                    },
                    color: 'black',
                    font: {
                      size: 16
                    },
                    align: -120,
                    clamp: true,
                    offset: 5
                  }
                },
                elements: {
                  line: {
                    borderWidth: (context: any) => context.dataset.label === 'Index Value' ? 4 : 2.5
                  }
                }
              }}
            />
          </DataProvider>

          <DataProvider src={urls.textBlockPage2}>
            <TextBlock
              // header='Page 2'
              // subHeader={`${year}-${month}`}
            />
          </DataProvider>

          <DataProvider src={urls.givingProfiles}>
            <CompositionBar
              options={{
                elements: {
                  bar: {
                    borderRadius: 10
                  }
                },
                scales: {
                  x: {
                    stacked: true,
                    display: false
                  },
                  y: {
                    stacked: true,
                    display: false
                  }
                },
                plugins: {
                  datalabels: {
                    display: true as const,
                    color: '#ffffff',
                    formatter: function (value: string) {
                      return value + '%'
                    },
                    anchor: 'start' as const,
                    offset: 6,
                    align: 'end' as const,
                    clamp: true,
                    font: {
                      weight: 500
                    }
                  }
                }
              }}
            />
          </DataProvider>

          <DataProvider src={urls.textBlockPage3}>
            <TextBlock
              // header='Page 3'
              // subHeader={`${year}-${month}`}
            />
          </DataProvider>

          <DataProvider src={urls.generousBehaviors}>
            <MetricsBar />
          </DataProvider>

          <DataProvider src={urls.generousBehaviorsWeekly}>
            <LineChart
              options={{
                responsive: true,
                plugins: {
                  legend: {
                    display: true,
                    position: 'right',
                    align: 'center',
                    labels: {
                      font: {
                        weight: 'bold'
                      },
                      usePointStyle: true
                    }
                  },
                  datalabels: {
                    display: false
                  }
                }
              }}
            />
          </DataProvider>
        </Stack>
        <Typography variant='body2' align='center' style={{ backgroundColor: 'whitesmoke', borderTopLeftRadius: '5px', borderTopRightRadius: '5px', margin: '2em 0 0 0', padding: '2em' }}>
          &copy; GivingTuesday.org. All rights Reserved.
        </Typography>
      </Container>
    </React.Fragment>
  )
}

export default App
